import { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

const AgentPage = lazy(() => import("src/pages/lab/agents/agents-page"));
const AgentCreate = lazy(() => import("src/pages/lab/agents/agent-create"));
const TeamPage = lazy(() => import("src/pages/lab/teams/teams-page"));
const TeamCreate = lazy(() => import("src/pages/lab/teams/team-create"));
const ToolPage = lazy(() => import("src/pages/lab/tools/tools-page"));
const ToolCreate = lazy(() => import("src/pages/lab/tools/tools-create"));
export const labsRoutes: RouteObject[] = [
  {
    path: paths.agents.create,
    element: <AgentCreate />,
  },
  {
    path: paths.agents.index,
    element: <AgentPage />,
  },
  {
    path: paths.agents.configure,
    element: <AgentPage />,
  },
  {
    path: paths.teams.create,
    element: <TeamCreate />,
  },
  {
    path: paths.teams.index,
    element: <TeamPage />,
  },
  {
    path: paths.teams.configure,
    element: <TeamPage />,
  },
  {
    path: paths.tools.create,
    element: <ToolCreate />,
  },
  {
    path: paths.tools.index,
    element: <ToolPage />,
  },
  {
    path: paths.tools.configure,
    element: <ToolPage />,
  },
];
