import { sql } from "drizzle-orm";
import { uniqueIndex, varchar } from "drizzle-orm/pg-core";
import type { DiscoverId } from "../../agid";
import { config, createBaseWithId, deletable, ownership } from "./base";
import { agConfig } from "./schema";

/** Discover **/
export const Discover = agConfig.table(
  "discover",
  {
    ...createBaseWithId<DiscoverId>(),
    ...ownership,
    ...config,
    ...deletable,
  },
  (table) => ({
    uniqueKey: uniqueIndex()
      .on(table.tenantId, table.ident, table.status)
      .where(sql`${table.status} != 'ARCHIVED'`),
  }),
);
