import type { FC, ReactNode } from "react";
import * as React from "react";

import { withAuthGuard } from "@/hocs/with-auth-guard";
import { paths } from "@/paths";
import {
  Box,
  ButtonBase,
  Collapse,
  Divider,
  lighten,
  Stack,
  Typography,
} from "@mui/material";
import { LabEntityColorMap } from "./lab-colors";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { agPink } from "src/styles/theme/colors";
import { icons } from "src/layouts/dashboard/layout/nav-icons";
import { isNavItemActive } from "src/lib/is-nav-item-active";
import { Link, useLocation } from "react-router-dom";

interface LayoutProps {
  children?: ReactNode;
}

interface LabLayoutContextProps {
  sideNavChildren?: ReactNode;
  setSideNavChildren: (children: ReactNode) => void;
}

export const LabLayoutContext = React.createContext<LabLayoutContextProps>({
  sideNavChildren: null,
  setSideNavChildren: () => {},
});

/** Hook to easily set sidenav children */
export const useUpdateLabSidebar = (newValue: ReactNode | null) => {
  const { setSideNavChildren } = React.useContext(LabLayoutContext);
  React.useEffect(() => {
    setSideNavChildren(newValue);
  }, []);
};

const SIDEBAR_WIDTH = "300px";

export const Layout: FC<LayoutProps> = withAuthGuard(({ children }) => {
  const [sideNavChildren, setSideNavChildren] = React.useState<ReactNode>(null);
  const [hide, setHide] = React.useState(false);

  // when window is fully resized, hide the sidebar
  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setHide(true);
      } else {
        setHide(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const LabNavBarItems = React.useMemo(() => {
    return (
      <Stack
        sx={{
          pt: 2,
          pb: 1,
          gap: 1,
        }}
      >
        {[
          {
            key: "skills",
            title: "Skills",
            icon: "skills",
            color: LabEntityColorMap.primary.agent,
            href: paths.agents.index,
            matcher: {
              type: "startsWith" as const,
              href: paths.agents.index,
            },
          },
          {
            key: "teams",
            title: "Teams",
            icon: "teams",
            href: paths.teams.index,
            color: LabEntityColorMap.primary.team,
            matcher: {
              type: "startsWith" as const,
              href: paths.teams.index,
            },
          },
          {
            key: "tools",
            title: "Tools",
            icon: "tools",
            href: paths.tools.index,
            color: LabEntityColorMap.primary.tool,
            matcher: {
              type: "startsWith" as const,
              href: paths.tools.index,
            },
          },
        ].map((item) => (
          <LabNavBarItem
            key={item.key}
            title={item.title}
            icon={item.icon}
            color={item.color}
            href={item.href}
            matcher={item.matcher}
          />
        ))}
      </Stack>
    );
  }, []);

  return (
    <LabLayoutContext.Provider
      value={{
        sideNavChildren,
        setSideNavChildren,
      }}
    >
      <Stack
        direction={"row"}
        maxHeight={"calc(100vh - var(--MainNav-height))"}
        overflow={"hidden"}
      >
        <Collapse in={!hide} orientation="horizontal" unmountOnExit>
          <Stack
            spacing={1}
            minWidth={SIDEBAR_WIDTH}
            maxWidth={SIDEBAR_WIDTH}
            sx={{
              borderRadius: 2,
            }}
          >
            {LabNavBarItems}
            {sideNavChildren}
          </Stack>
        </Collapse>
        <ExpandDividerButton hide={hide} setHide={setHide} />
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            overflowX: "hidden",
            overflowY: "scroll",
            width: `calc(100% - ${SIDEBAR_WIDTH})`,
          }}
        >
          <Stack sx={{ height: "calc(100% - 32px)" }}>{children}</Stack>
        </Box>
      </Stack>
    </LabLayoutContext.Provider>
  );
});

const LabNavBarItem = ({
  title,
  icon,
  color,
  href,
  matcher,
}: {
  title: string;
  icon: string;
  color: string;
  href: string;
  matcher: {
    type: "startsWith" | "equals";
    href: string;
  };
}) => {
  const location = useLocation();
  const isActive = React.useMemo(() => {
    return isNavItemActive({
      disabled: false,
      external: false,
      pathname: location.pathname,
      href: href,
      matcher: matcher,
    });
  }, [location, href, matcher]);

  const Icon = icons[icon as keyof typeof icons];

  return (
    <>
      <Link to={href} style={{ textDecoration: "none", color: "inherit" }}>
        <Stack
          direction={"row"}
          gap={2}
          sx={{
            backgroundColor: isActive ? color : "transparent",
            color: isActive ? "white" : "inherit",
            padding: "6px 8px",
            margin: "0px 16px",
            borderRadius: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            transition: "background-color 0.2s",
            "&:hover": {
              "--icon-color": isActive ? "white" : color,
              backgroundColor: isActive ? "none" : lighten(color, 0.9),
            },
          }}
        >
          <Icon
            weight={isActive ? "fill" : undefined}
            fontSize={"20px"}
            style={{
              marginLeft: "7px",
              transition: "color 0.1s",
            }}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {title}
          </Typography>
        </Stack>
      </Link>
      {/* </ButtonBase> */}
    </>
  );
};

const ExpandDividerButton = ({
  hide,
  setHide,
}: {
  hide: boolean;
  setHide: (hide: boolean) => void;
}) => (
  <Stack
    direction={"row"}
    alignItems={"center"}
    sx={{
      position: "absolute",
      left: hide ? "0" : `calc(${SIDEBAR_WIDTH} - 12px)`,
      transition: "all 0.2s",
    }}
  >
    <Divider
      orientation="vertical"
      sx={{
        height: "calc(100vh - var(--MainNav-height))",
        ml: "10px",
        opacity: hide ? 0 : 1,
      }}
    />
    <ButtonBase
      onClick={() => setHide(!hide)}
      sx={{
        ml: hide ? "0px" : "-13px",
        height: "40px",
        zIndex: 10,
        opacity: "var(--sidebar-hover)",
        transition: "all 0.2s",
        display: "flex",
        alignItems: "center",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))",

        //  Element to cut out the divider
        "&::before": {
          content: "''",
          display: "block",
          width: "4px",
          height: "50px",
          position: "absolute",
          left: "12px",
          top: "-4px",
          backgroundColor: hide ? "none" : "#FAFAFA",
          zIndex: "-1",
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          background: hide ? "none" : "white",
          color: agPink[300],
          backgroundColor: "rgba(255, 255, 255, 1)",
          border: `1px solid ${agPink[300]}`,
          padding: "4px",
          fontSize: "16px",
          display: "flex",
          transition: "all 0.2s",
          ":hover": {
            filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.05))",
            transform: "scale(1.05)",
          },
        }}
      >
        {hide ? <CaretRight weight="bold" /> : <CaretLeft weight="bold" />}
      </Box>
    </ButtonBase>
  </Stack>
);
