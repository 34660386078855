import { z } from "zod";

export interface TenantConfiguration {
  /**
   * TenantConfiguration is the data we store in the DB for a tenant.
   *
   * Note: this is different from the TenantConfig model that is made available to the notebooks and other services.
   * as there might be additional logic performed and not all data is stored in the DB or available to the notebooks.
   */

  /**
   * To make migrations of this data easier in the future, just keep a version tag that is associated with the code version
   */
  version: number;

  /**
   * Expiry of the service token for this tenant.
   */
  serviceTokenExpiry?: string;

  /**
   * Official name of the company associated with this tenant.
   */
  companyName?: string;

  /**
   * Industry of the company associated with this tenant.
   */
  companyIndustry?: string;

  /**
   * Name of the database in Snowflake containing this tenant's data.
   */
  snowflakeDatabase?: string;

  /**
   * Prefix in the ML S3 bucket for the tenant's data.
   */
  s3Prefix?: string;

  /**
   * Name of the Airbyte workspace for the tenant.
   */
  airbyteWorkspace?: string;

  /**
   * Location of the AGx data, including the AG3_dataset and other standard tables.
   * This has been added to allow for SQLLite for initial location.
   *
   */
  /**
   * SSO configuration for the tenant, only required for SAML Auth
   */
  ssoConfig?: SSOConfig;

  /**
   * Integrations Secrets Key
   */
  integrationEncryptionKeyArn?: string;

  serviceIntegrationId?: string;
}

export interface SSOConfig {
  /**
   * Information required for WorkOS SSO Config
   */
  authProvider: string;
  authConnectionId: string;
  authDomain: string;
}

/**
 * Adding S3Path and S3Prefix as custom types
 */
const brand = Symbol("s3KeyType");

export type S3Path = string & { [brand]: "s3Path" };
export type S3Prefix = string & { [brand]: "s3Prefix" };

function isS3Path(s: string): s is S3Path {
  // TODO: Add more validation
  return s.startsWith("s3://") && !s.endsWith("/");
}

function isS3Prefix(s: string): s is S3Prefix {
  // TODO: Add more validation
  return s.startsWith("s3://") && s.endsWith("/");
}

export const S3Path = z.string().refine(isS3Path, (val) => ({
  message: `Invalid S3 path: ${val}`,
}));

export const S3Prefix = z.string().refine(isS3Prefix, (val) => ({
  message: `Invalid S3 prefix: ${val}`,
}));
